import Flex from 'components/common/Flex';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Form, Button, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import ChatProvider from './ChatProvider';
import ChatContactsSearch from './sidebar/ChatContactSearch';
import SimpleBarReact from 'simplebar-react';
import classNames from 'classnames';
import ChatContentHeader from './content/ChatContentHeader';
import { requestGetContacts, requestGetMessages, requestSendMessage } from './Middleware/action';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Message from './content/Message';
import TextareaAutosize from 'react-textarea-autosize';
import Picker from '@emoji-mart/react';
import { adminPhone } from 'helpers/admin';
import Pusher from 'pusher-js';
import LastMessage from './sidebar/LastMessage';
import TemplateModal from './content/TemplateModal';
let pusher = new Pusher('c7003f808de28111c5f3', {
  cluster: 'ap2',
});

const Chat = () => {
  // const { contacts, requestInPending, requestInSuccess } = useSelector(store => store.chat);
  const dispatch = useDispatch();
  const [hideSidebar, setHideSidebar] = useState(false);
  const [allContacts, setContacts] = useState([]);
  const [allMessages, setMessages] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const messagesEndRef = useRef();
  const [previewEmoji, setPreviewEmoji] = useState(false);
  const [message, setMessage] = useState('');
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [chatPage, setChatPage] = useState(0);
  const [chatBlocked, setChatBlocked] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const scrollRef = useRef(null);

  const addEmoji = e => {
    let emoji = e.native;
    setMessage(message + emoji);
    setPreviewEmoji(false);
  };

  useEffect(() => {
    setPreviewEmoji(false);
  }, []);

  useEffect(() => {
    getContacts();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      global.selectedUserPhone = selectedUser;
      socketInitializer(selectedUser);
      return () => {
        pusher.unsubscribe("navoto_channel" + adminPhone);
      };
    }
  }, [selectedUser])

  const socketInitializer = (selectedUser) => {
    var channel = pusher.subscribe("navoto_channel" + adminPhone);
    channel.bind('navoto_message', (data) => {
      console.log("pusher data...", data);
debugger

      console.log("global.selectedUserPhone ", global.selectedUserPhone);
      const messageData = { roomId: data.roomId, from: data.from, to: data.to || adminPhone, message: data.message, createdAt: new Date().toISOString() };

      if (global.selectedUserPhone && (global.selectedUserPhone == data.from || global.selectedUserPhone == data.to)) {
        setMessages((currentMsg) => [
          ...currentMsg,
          messageData
        ]);
        setScrollToBottom(true);
      } else {
        // set unread count
        setLastMessageAndUnread(messageData, data);
      }
    })
  }

  const setLastMessageAndUnread = (messageData, msg) => {
    if (allContacts.length > 0) {
      const contacts = [...allContacts];
      const updatedContacts = contacts.map(contact => {
        if (contact.phoneNumber === msg.from) {
          return {
            ...contact,
            lastMessage: messageData,
            unread: (contact.unread || 0) + 1 // Increment unread count by 1
          };
        }
        if (adminPhone === msg.from && msg.to === contact.phoneNumber) {
          return {
            ...contact,
            lastMessage: messageData
          };
        }
        return contact;
      });
      setContacts(updatedContacts);
    }
  }

  const getContacts = async () => {
    const onSuccess = (data) => {
      setContacts(data.contacts);
    }
    dispatch(requestGetContacts({ onSuccess }));
  }

  const getMessages = async (selectedUserPhone) => {

    // set unread messages to 0
    const contacts = [...allContacts];
    const updatedContacts = contacts.map(contact => {
      if (selectedUserPhone === contact.phoneNumber) {
        return {
          ...contact,
          unread: 0
        };
      }
      return contact;
    });

    setContacts(updatedContacts);
    setSelectedUser(selectedUserPhone);

    const onSuccess = (data) => {
      const reverseOfMessages = data.messages.reverse();
      var allMessages1 = [];
      if (chatPage === 0) {
        allMessages1 = reverseOfMessages;
      } else {
        allMessages1 = [...reverseOfMessages, ...allMessages];
      }
      const uniqueMessages = allMessages1.reduce((unique, message) => {
        const existingMessage = unique.find(u => u._id === message._id);
        if (!existingMessage) {
          unique.push(message);
        }
        return unique;
      }, []);
      console.log("uniqueMessages...", uniqueMessages);
      setMessages(uniqueMessages);
      if (chatPage === 0) {
        setScrollToBottom(true);
      }
    }
    dispatch(requestGetMessages({ phoneNumber: selectedUserPhone, page: chatPage, limit: 20, onSuccess }));
  }

  useEffect(() => {
    handleBlockMessages();
  }, [allContacts, selectedUser, allMessages])

  const handleBlockMessages = () => {
    if (selectedUser && allContacts.length > 0 && allMessages.length > 0) {
      const lastMessage = allContacts.find(x => x.phoneNumber === selectedUser)?.lastMessage;
      if (lastMessage && lastMessage.from !== selectedUser) {
        const twentyFourHoursAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
        const lMessage = [...allMessages.filter(x => x.from === selectedUser)]?.pop()?.createdAt;
        const lastMessageTime = new Date(lMessage);
        if (lastMessageTime < twentyFourHoursAgo) {
          setChatBlocked(true);
        } else {
          setChatBlocked(false);
        }
      }
    }
  }

  const handleSendTemplate = () => {
    if (chatBlocked) {
      setTemplateModalOpen(true);
    }
  }

  const handleSendTemplateMessage = (templateName) => {
    sendMessage(templateName);
    setChatBlocked(false);
  }

  const handleSubmit = e => {
    e.preventDefault();
    sendMessage();
  };

  const sendMessage = (templateName = "") => {
    const messageData = {
      roomId: `${adminPhone}-${selectedUser}`,
      from: adminPhone,
      to: selectedUser,
      message: message || templateName || "",
      createdAt: new Date().toISOString()
    }

    const messages = [...allMessages]; //[...allMessages, ...[messageData]]
    messages.push(messageData);
    setMessages(messages);

    const onSuccess = (data) => {
      setLastMessageAndUnread(messageData, messageData);
    }

    dispatch(requestSendMessage({ onSuccess, to: selectedUser, message: message, templateName: templateName }));

    setMessage('');
    setScrollToBottom(true);
  }

  useEffect(() => {
    if (scrollToBottom) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);


  const handleScroll = (e) => {
    // Your scroll event logic here
    if (e.currentTarget.scrollTop === 0) {
      setChatPage((prevPage) => {
        const newPage = prevPage + 1;
        console.log('Scrolling', newPage);
        return newPage; // Return the updated page number
      });

    }
  };

  useEffect(() => {

    const node = scrollRef.current?.getScrollElement(); // Use getScrollElement() to access the internal scrollable div
    if (node) {
      node.addEventListener('scroll', handleScroll);
    }

    // return () => {
    //   if (node) {
    //     node.removeEventListener('scroll', handleScroll);
    //   }
    // };
  }, []);

  useEffect(() => {
    //  if(chatPage > 0){
    getMessages(global.selectedUserPhone)
    //  }
  }, [chatPage])

  return (
    <>

      <ChatProvider>
        <Card className="card-chat overflow-hidden">
          <Card.Body as={Flex} className="p-0 h-100">

            <div className={classNames('chat-sidebar', { 'start-0': hideSidebar })}>
              <div className="contacts-list">
                <SimpleBarReact style={{ height: '100%', minWidth: '65px' }} >
                  {allContacts?.map((user, i) => {
                    return (
                      <Nav className="border-0" onClick={((e) => {
                        e.preventDefault();
                        setChatPage(() => 0);
                        global.selectedUserPhone = user.phoneNumber;
                        getMessages(user.phoneNumber);
                      })} key={i}>
                        <Nav.Link
                          // eventKey={index}
                          className={`chat-contact hover-actions-trigger p-3 ${user.unread > 0 ? 'unread-message' : 'read-message'}`}
                        >
                          <Flex>
                            {/* <Avatar className={user.status} src={user.avatarSrc} size="xl" /> */}
                            <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
                              <Flex justifyContent="between" >
                                <h6 className="mb-0 chat-contact-title">
                                  {user.phoneNumber}
                                </h6>
                                <span className="message-time fs--2">
                                  {user.unread || ""}
                                </span>
                              </Flex>

                              <div className="min-w-0">
                                <div className="chat-contact-content pe-3">
                                  <LastMessage lastMessage={user.lastMessage} />
                                </div>
                              </div>
                            </div>
                          </Flex>
                        </Nav.Link>
                      </Nav>
                    )
                  })}
                </SimpleBarReact>
              </div>
              <ChatContactsSearch />
            </div>

            <div className='card-chat-content' onClick={handleSendTemplate}>
              <div className='card-chat-pane'>
                <div className="chat-content-header">
                  <Row className="flex-between-center">
                    <Col xs={6} md={8} as={Flex} alignItems="center">
                      <div
                        className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer"
                        onClick={() => setHideSidebar(true)}
                      >
                        <FontAwesomeIcon icon="chevron-left" />
                      </div>
                      <div className="min-w-0">
                        <h5 className="mb-0 text-truncate fs-0">
                          {selectedUser}
                        </h5>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className="chat-content-body" style={{ display: 'inherit' }}>
                  <SimpleBarReact ref={scrollRef} style={{ height: '100%' }}>
                    <div className="chat-content-scroll-area">
                      {allMessages.map((message, index) => {
                        return (
                          <div key={index}>
                            <Message
                              message={message}
                            />
                          </div>
                        )
                      })}
                    </div>
                    <div ref={messagesEndRef} />
                  </SimpleBarReact>
                </div>
              </div>

              <Form className={`chat-editor-area ${chatBlocked ? 'div-disabled' : ''}`} onSubmit={handleSubmit}>
                <TextareaAutosize
                  minRows={1}
                  maxRows={6}
                  value={message}
                  placeholder="Type your message"
                  onChange={({ target }) => setMessage(target.value)}
                  className="form-control outline-none resize-none rounded-0 border-0 emojiarea-editor"
                />

                <Form.Group controlId="chatFileUpload">
                  <Form.Label className="chat-file-upload cursor-pointer">
                    <FontAwesomeIcon icon="paperclip" />
                  </Form.Label>
                  <Form.Control type="file" className="d-none" />
                </Form.Group>

                <Button
                  variant="link"
                  className="emoji-icon "
                  onClick={() => setPreviewEmoji(!previewEmoji)}
                >
                  <FontAwesomeIcon
                    icon={['far', 'laugh-beam']}
                    onClick={() => setPreviewEmoji(!previewEmoji)}
                  />
                </Button>

                {previewEmoji && (
                  <div className="chat-emoji-picker" dir="ltr">
                    <Picker
                      set="google"
                      onEmojiSelect={addEmoji}
                      theme='light'
                      previewPosition="none"
                      skinTonePosition="none"
                    />
                  </div>
                )}

                <Button
                  variant="send"
                  size="sm"
                  className={classNames('shadow-none', {
                    'text-primary': message.length > 0
                  })}
                  type="submit"
                >
                  Send
                </Button>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </ChatProvider>
      {(templateModalOpen && chatBlocked) && (
        <TemplateModal show={templateModalOpen && chatBlocked} handleClose={(e) => { setTemplateModalOpen(false) }} sendTemplateMessage={handleSendTemplateMessage} />
      )}
    </>
  );
};

export default Chat;
